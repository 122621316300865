/*******************************
      Slider Listing
*******************************/
.slider-listing .slick-slide.slick-active:last-child {
  margin-right: 0em;
}
.block.listing.slider_listing .pagination-wrapper {
  display: none;
}
.slider-listing .slick-track > div.slick-slide {
  border-top: 4px transparent solid;
}
.slider-listing .slick-track > div.slick-slide:before {
  display: block;
  border-top: solid 4px #019fb6;
  margin-top: -4px;
  content: '';
  transform: scaleX(0);
  transform-origin: 0% 50%;
  transition: transform 250ms ease-in-out;
}
.slider-listing .slick-track > div.slick-slide:hover:before {
  transform: scaleX(1);
  transform-origin: 0% 50%;
}
.slider-listing .slick-track > div.slick-slide:nth-child(4n + 1):before {
  border-top-color: #ef12a6 !important;
}
.slider-listing .slick-track > div.slick-slide:nth-child(4n + 2):before {
  border-top-color: #fbba00 !important;
}
.slider-listing .slick-track > div.slick-slide:nth-child(4n + 3):before {
  border-top-color: #28b9f7 !important;
}
.slider-listing .slick-track > div.slick-slide:nth-child(4n + 4):before {
  border-top-color: #97cc0a !important;
}
.slider-listing .slick-slide {
  padding-right: 3px;
  padding-left: 3px;
}
.slider-listing .slider-carousel-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.slider-listing .slider-nav {
  display: flex;
  justify-content: end;
  padding: 0.6em 0px;
  transition: opacity 0.2s ease-in-out;
}
.slider-listing .slider-nav button {
  cursor: pointer;
  outline: none;
  background-color: unset;
  margin: unset;
  padding: unset;
  color: unset;
  border: unset;
  color: #000000;
  border-radius: unset;
  padding-top: 0.5rem;
  text-transform: uppercase;
  font-size: 1.25rem;
  display: inline-block;
  font-family: 'Precious Sans Two Bold', 'Helvetica Neue', Arial, Helvetica, sans-serif !important;
  font-weight: normal;
  text-decoration: none !important;
  border-bottom: 4px #97cc0a solid;
  padding-left: 0px;
  padding-right: 0px;
  border-left: 0px;
  border-right: 0px;
  border-color: transparent;
}
.slider-listing .slider-nav button a {
  text-decoration: none !important;
}
.slider-listing .slider-nav button:hover {
  color: unset;
}
.slider-listing .slider-nav button:after {
  display: block;
  border-bottom: solid 4px #FFFFFF;
  margin-bottom: -4px;
  content: '';
  transform: scaleX(0);
  transform-origin: 0% 50%;
  transition: transform 250ms ease-in-out;
}
.slider-listing .slider-nav button:hover:after {
  transform: scaleX(1);
  transform-origin: 0% 50%;
}
.slider-listing .slider-nav button:hover {
  border-bottom: 4px #97cc0a solid;
}
.slider-listing .slider-nav button:hover:after {
  display: block;
  border-bottom: solid 4px #FFFFFF;
  margin-bottom: -4px;
  content: '';
  transform: scaleX(0);
  transform-origin: 0% 50%;
  transition: transform 250ms ease-in-out;
}
.slider-listing .slider-nav button:hover:hover:after {
  transform: scaleX(1);
  transform-origin: 0% 50%;
}
.slider-listing .slider-nav button.hidden {
  visibility: hidden;
}
