#page-document > .image-gallery {
  margin-left: unset;
}
.image-gallery .image-gallery-cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1em;
}
.image-gallery .image-gallery-cards .card-img {
  border-top: 4px transparent solid;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 1em;
  background-color: #ffffff;
}
.image-gallery .image-gallery-cards .card-img:before {
  display: block;
  border-top: solid 4px #019fb6;
  margin-top: -4px;
  content: '';
  transform: scaleX(0);
  transform-origin: 0% 50%;
  transition: transform 250ms ease-in-out;
}
.image-gallery .image-gallery-cards .card-img:hover:before {
  transform: scaleX(1);
  transform-origin: 0% 50%;
}
.image-gallery .image-gallery-cards .card-img:nth-child(4n + 1):before {
  border-top-color: #ef12a6 !important;
}
.image-gallery .image-gallery-cards .card-img:nth-child(4n + 2):before {
  border-top-color: #fbba00 !important;
}
.image-gallery .image-gallery-cards .card-img:nth-child(4n + 3):before {
  border-top-color: #28b9f7 !important;
}
.image-gallery .image-gallery-cards .card-img:nth-child(4n + 4):before {
  border-top-color: #97cc0a !important;
}
@media only screen and (max-width: 991px) {
  .image-gallery .image-gallery-cards .card-img {
    flex-basis: calc(100% / 2 - 1em) !important;
  }
}
@media only screen and (max-width: 767px) {
  .image-gallery .image-gallery-cards .card-img {
    flex-basis: calc(100% / 1 - 1em) !important;
  }
}
.image-gallery .image-gallery-cards .card-3x {
  flex-basis: calc(100% / 3 - 1em);
}
.image-gallery .image-gallery-cards .card-4x {
  flex-basis: calc(100% / 4 - 1em);
}
