.block-editor-group [data-rbd-draggable-context-id] {
  margin-bottom: 1rem;
}
.block-editor-group .block-add-button {
  display: none !important;
}
.block-editor-group .block.group.selected::before,
.block-editor-group .block.group:hover::before {
  border-style: dashed;
}
.block-editor-group fieldset {
  border: none;
}
.block-editor-group fieldset legend {
  position: absolute;
  z-index: 3;
  top: -1.3em;
  left: 0;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0 1rem;
  margin-right: auto;
  margin-left: auto;
  background-color: #FFFFFF;
  color: rgba(120, 192, 215, 0.75);
  cursor: pointer;
  text-align: center;
}
.block-editor-group .section-block {
  padding-top: 1rem;
  padding-bottom: 0.1rem;
  margin: 0;
}
.block-editor-group .counter {
  display: grid;
  align-items: center;
  font-size: 85%;
  grid-gap: 0.5em;
  grid-template-columns: 98% auto;
  text-align: end;
}
.block-editor-group .counter.info {
  color: #ccc;
}
.block-editor-group .counter.danger {
  color: crimson;
}
.block-editor-group .counter.warning {
  color: darkorange;
}
.block-editor-group .blocks-form {
  margin-top: 0.5rem;
}
.block-editor-group .blocks-chooser {
  right: 0;
  left: auto;
  margin-top: 3rem;
}
.block-editor-group .block-toolbar {
  position: absolute;
  z-index: 3;
  right: -9px;
  display: flex;
  border: none;
  border: 1px solid rgba(120, 192, 215, 0.75);
  border-bottom: 1px solid #FFFFFF;
  margin-top: -45px;
  background-color: #FFFFFF;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}
.block-editor-group .block-toolbar .ui.basic.button {
  padding: 8px 5px;
}
.block-editor-group .block-toolbar .ui.basic.button:hover {
  background: transparent !important;
}
