.agenda-listing .agenda-listing-content {
  display: flex;
}
@media only screen and (max-width: 767px) {
  .agenda-listing .agenda-listing-content {
    flex-direction: column;
  }
}
.agenda-listing .agenda-listing-content .column-one {
  display: flex;
  width: 66.6%;
}
@media only screen and (max-width: 767px) {
  .agenda-listing .agenda-listing-content .column-one {
    width: 100%;
  }
}
.agenda-listing .agenda-listing-content .column-two {
  display: flex;
  width: 33.3%;
  flex-direction: column;
}
@media only screen and (max-width: 767px) {
  .agenda-listing .agenda-listing-content .column-two {
    width: 100%;
  }
}
.agenda-listing .agenda-listing-content .column-two > * {
  flex-grow: 1;
}
.agenda-listing .agenda-listing-content .primary-item {
  border-top: 4px transparent solid;
}
.agenda-listing .agenda-listing-content .primary-item:before {
  display: block;
  border-top: solid 4px #019fb6;
  margin-top: -4px;
  content: '';
  transform: scaleX(0);
  transform-origin: 0% 50%;
  transition: transform 250ms ease-in-out;
}
.agenda-listing .agenda-listing-content .primary-item:hover:before {
  transform: scaleX(1);
  transform-origin: 0% 50%;
}
.agenda-listing .agenda-listing-content .primary-item .image-container {
  overflow: hidden;
  position: relative;
  height: 100%;
  z-index: 0;
  max-height: 50%;
}
.agenda-listing .agenda-listing-content .primary-item img {
  transition: transform 0.2s ease;
}
.agenda-listing .agenda-listing-content .primary-item:hover img {
  transform: scale(1.1);
}
.agenda-listing .agenda-listing-content .primary-item .primary-item-header {
  position: absolute;
  z-index: 1;
  padding: 2em;
  color: white;
  text-shadow: 0px 0px 4px #000000;
  width: clamp(20rem, 45vw, 40rem);
}
.agenda-listing .agenda-listing-content .primary-item .primary-item-header .date time {
  font-size: clamp(1.125rem, 2.75vw, 1.875rem);
  line-height: clamp(1.35rem, 2.75vw, 2.1rem);
  color: #FFFFFF;
  text-transform: uppercase;
  font-family: 'Precious Sans Two Bold', 'Helvetica Neue', Arial, Helvetica, sans-serif;
}
.agenda-listing .agenda-listing-content .primary-item .primary-item-header a {
  color: #FFFFFF;
}
.agenda-listing .agenda-listing-content .primary-item .primary-item-header h3 {
  margin: 0em;
  font-family: 'Precious Sans Two Light', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  text-transform: none;
  font-size: clamp(1.875rem, 3.75vw, 3.75rem);
  line-height: clamp(1.875rem, 3.75vw, 3.75rem);
  color: #FFFFFF;
  margin-top: 0.625rem;
}
.agenda-listing .agenda-listing-content .primary-item img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.agenda-listing .agenda-listing-content .primary-item .image-container {
  max-height: unset;
}
.agenda-listing .agenda-listing-content .secondary-item {
  border-top: 4px #f1f1f1 solid;
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  min-height: calc(25%-4px);
}
.agenda-listing .agenda-listing-content .secondary-item:before {
  display: block;
  border-top: solid 4px #019fb6;
  margin-top: -4px;
  content: '';
  transform: scaleX(0);
  transform-origin: 0% 50%;
  transition: transform 250ms ease-in-out;
}
.agenda-listing .agenda-listing-content .secondary-item:hover:before {
  transform: scaleX(1);
  transform-origin: 0% 50%;
}
.agenda-listing .agenda-listing-content .secondary-item:nth-child(4n + 1):before {
  border-top-color: #ef12a6 !important;
}
.agenda-listing .agenda-listing-content .secondary-item:nth-child(4n + 2):before {
  border-top-color: #fbba00 !important;
}
.agenda-listing .agenda-listing-content .secondary-item:nth-child(4n + 3):before {
  border-top-color: #28b9f7 !important;
}
.agenda-listing .agenda-listing-content .secondary-item:nth-child(4n + 4):before {
  border-top-color: #97cc0a !important;
}
.agenda-listing .agenda-listing-content .secondary-item .inner {
  padding: 1em;
}
.agenda-listing .agenda-listing-content .secondary-item .inner time {
  text-transform: uppercase;
  font-family: 'Precious Sans Two Bold', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-size: clamp(1.125rem, 2.75vw, 1.875rem);
  line-height: clamp(1.563rem, 2.75vw, 2.625rem);
}
.agenda-listing .agenda-listing-content .secondary-item .inner h3 {
  font-size: clamp(1.125rem, 2.75vw, 1.875rem);
  line-height: clamp(1.563rem, 2.75vw, 2.625rem);
  text-transform: none;
  font-family: 'Precious Sans Two Light', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  margin: 0;
}
.agenda-listing .agenda-listing-content .secondary-item .inner a {
  color: #000000;
}
