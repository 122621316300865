#view .quoteBlock-wrapper {
  min-height: 3em;
  padding-right: 5rem;
  padding-left: 7.5rem;
  margin-left: 30%;
  background: url(/static/media/quotes.5adf2965.svg) no-repeat top left;
  font-size: clamp(1.125rem, 2.75vw, 1.75rem);
  line-height: clamp(1.35rem, 2.75vw, 2.1rem);
}
#view .quoteBlock-wrapper p {
  margin-left: 1rem !important;
}
