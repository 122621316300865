#page-document > .image-carousel {
  margin-left: 30%;
}
#page-document > .image-carousel.image-carousel-imageCards {
  margin-left: unset;
}
.image-carousel {
  margin-top: 1em;
  margin-bottom: 3em;
}
.image-carousel .slide-caption {
  margin-top: 1em;
}
.image-carousel .slide-caption h4 {
  font-size: clamp(0.875rem, 2.25vw, 1.125rem);
  line-height: clamp(1.026rem, 2.25vw, 1.318rem);
  margin-bottom: 0px;
}
.image-carousel .slide-caption p {
  font-size: clamp(0.875rem, 2.25vw, 1.125rem);
  line-height: clamp(1.026rem, 2.25vw, 1.318rem);
}
.image-carousel .slide-img {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 1em;
}
.image-carousel .slide-img .ui.placeholder {
  width: 100%;
  height: 100%;
}
.image-carousel .slide-img a {
  display: flex;
  max-width: 100%;
  max-height: 100%;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
}
.image-carousel .slide-img img.bg-image {
  width: 100%;
  height: 100%;
  max-height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.image-carousel .slick-arrow {
  display: unset !important;
  z-index: 1;
  left: 10px;
  width: 50%;
  height: 100%;
  text-align: left;
}
.image-carousel .slick-arrow.slick-next {
  right: 25px;
  left: unset;
  text-align: right;
}
.image-carousel .slick-arrow.slick-prev:before,
.image-carousel .slick-arrow.slick-next:before {
  color: white;
  font-family: 'Precious Sans Two Medium', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-size: 3rem;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.9);
}
#page-document .image-carousel.image-carousel-imageCards .slide-img {
  align-items: center;
  flex-direction: row;
  background-color: #FFFFFF;
  margin-right: 0.25rem;
  padding-right: 0em;
}
#page-document .image-carousel.image-carousel-imageCards .slide-img img.bg-image {
  width: -moz-min-content;
  width: min-content;
  height: -moz-min-content;
  height: min-content;
  -o-object-fit: contain;
     object-fit: contain;
}
#page-document .image-carousel.image-carousel-imageCards .slider-carousel-footer {
  justify-content: flex-end;
}
#page-document .image-carousel.image-carousel-imageCards .slider-carousel-footer .slider-pagination {
  display: none !important;
  color: red;
}
