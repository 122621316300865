.title-cards {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.title-cards .title-card {
  display: flex;
  min-height: 7rem;
  align-items: center;
  padding: 0rem 2rem;
  background-color: #FFFFFF;
  color: #000000;
  gap: 1em;
}
.title-cards .title-card:hover {
  background-color: #fbba00;
  transition: background-color 1.4s;
}
.title-cards .title-card h3 {
  flex: 33% 1;
  margin: unset;
  font-family: 'Precious Sans Two Light', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-size: clamp(1.75rem, 3.75vw, 4.063rem);
  line-height: clamp(2.1rem, 3.75vw, 4.063rem);
}
.title-cards .title-card > * {
  flex-grow: 1;
}
.title-cards .title-card .title-card-text {
  flex: 40% 1;
  flex-grow: 0;
  padding: 1rem 0rem;
  font-size: clamp(0.875rem, 2.75vw, 1.25rem);
  line-height: clamp(1rem, 2.75vw, 1.5rem);
}
.title-cards .title-card .title-card-image {
  display: flex;
  flex-direction: row-reverse;
}
.title-cards .title-card .title-card-image img {
  width: 5em;
  height: 100%;
  flex-grow: 0;
}
.title-cards .title-card .title-card-image img.bigger {
  width: 9em;
  margin: 1em 0;
}
.title-cards .title-card a {
  margin: -1em -2em;
}
@media only screen and (max-width: 767px) {
  .title-cards .title-card {
    display: grid !important;
    padding: 1rem 1rem;
    margin: unset;
    grid-template-areas: 'title image' 'desc image';
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
  .title-cards .title-card h3 {
    grid-area: title;
  }
  .title-cards .title-card .title-card-text {
    padding: unset;
    grid-area: desc;
  }
  .title-cards .title-card .title-card-image {
    grid-area: image;
  }
}
