.facts-grid {
  display: grid;
  margin-top: clamp(3rem, 11vw, 8.5rem);
  gap: 4px;
  grid-auto-flow: row;
  grid-template-areas: 'block-title tall .' 'block-title tall .' '. wide wide';
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
}
@media only screen and (max-width: 767px) {
  .facts-grid {
    grid-template-areas: 'block-title block-title' 'tall tall' '. .' '. wide';
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 2fr 2fr 2fr;
  }
}
.facts-grid-block-title {
  grid-area: block-title;
}
.facts-grid-block-title h2 {
  font-family: 'Precious Sans Two Medium', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  font-size: clamp(2rem, 4.25vw, 4.06rem);
  line-height: clamp(2.4rem, 4.25vw, 4.875rem);
  margin-top: unset !important;
}
@media only screen and (max-width: 767px) {
  .facts-grid-block-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .facts-grid-block-title h2 {
    width: 10ch;
    margin: unset;
    margin-top: 1rem !important;
  }
}
.facts-grid-wide-cell {
  grid-area: wide;
}
.facts-grid-tall-cell {
  grid-area: tall;
}
.fact-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: clamp(0.75rem, 2.25vw, 1.5rem);
  background-color: white;
}
.fact-card .title {
  font-size: clamp(1.75rem, 4.25vw, 4.06rem);
  line-height: clamp(2.1rem, 4.25vw, 4.875rem);
}
.fact-card .subtitle {
  margin-bottom: 0.75rem;
  font-size: clamp(0.875rem, 4.25vw, 1.25rem);
  line-height: clamp(1.026rem, 4.25vw, 1.465rem);
}
.fact-card img {
  width: auto;
  height: 100%;
  max-height: 100px;
  align-self: flex-start;
}
