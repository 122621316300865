.block.imagecards-videocarousel .slider-wrapper {
  height: 100vh;
}
@media only screen and (max-width: 767px) {
  .block.imagecards-videocarousel .slider-wrapper {
    height: unset;
  }
}
.block.imagecards-videocarousel .slider-wrapper .slick-slider {
  height: 100%;
}
@media only screen and (max-width: 767px) {
  .block.imagecards-videocarousel .slider-wrapper .slick-slider {
    height: unset;
  }
}
.block.imagecards-videocarousel .slider-wrapper .slick-slider .slick-list {
  height: 100%;
}
@media only screen and (max-width: 767px) {
  .block.imagecards-videocarousel .slider-wrapper .slick-slider .slick-list {
    height: unset;
  }
}
.block.imagecards-videocarousel .slider-wrapper .slick-slider .slick-list .slick-track {
  height: 100%;
}
@media only screen and (max-width: 767px) {
  .block.imagecards-videocarousel .slider-wrapper .slick-slider .slick-list .slick-track {
    height: unset;
  }
}
.block.imagecards-videocarousel .slider-wrapper .slick-slider .slick-list .slick-track .slick-slide > div {
  height: 100%;
}
@media only screen and (max-width: 767px) {
  .block.imagecards-videocarousel .slider-wrapper .slick-slider .slick-list .slick-track .slick-slide > div {
    height: unset;
  }
}
.block.imagecards-videocarousel .slider-wrapper .slick-slider .slick-list .slick-track .slick-slide .slider-slide {
  height: 100%;
}
@media only screen and (max-width: 767px) {
  .block.imagecards-videocarousel .slider-wrapper .slick-slider .slick-list .slick-track .slick-slide .slider-slide {
    height: unset;
    aspect-ratio: 1.77777778;
    width: 100vw;
  }
}
.block.imagecards-videocarousel .slider-wrapper .slick-slider .slick-list .slick-track .slick-slide .slider-slide .video-responsive {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100vh;
  min-width: 100%;
  height: 100vh;
  min-height: 100%;
  transform: translate(-50%, -50%);
}
@media only screen and (max-width: 767px) {
  .block.imagecards-videocarousel .slider-wrapper .slick-slider .slick-list .slick-track .slick-slide .slider-slide .video-responsive {
    height: unset;
    width: 100vw;
  }
  .block.imagecards-videocarousel .slider-wrapper .slick-slider .slick-list .slick-track .slick-slide .slider-slide .video-responsive .videoCarousel {
    width: 100% !important;
    height: 100% !important;
  }
}
.block.imagecards-videocarousel .slider-wrapper .slick-slider .slick-list .slick-track .slick-slide .slider-slide .slider-footer {
  position: absolute;
  z-index: 1;
  bottom: 2em;
  left: 10%;
  display: flex;
  width: 80%;
  justify-content: space-between;
}
.block.imagecards-videocarousel .slider-wrapper .slick-slider .slick-list .slick-track .slick-slide .slider-slide .slider-footer .video-carousel-dots {
  display: flex;
  flex-direction: column-reverse;
}
.block.imagecards-videocarousel .slider-wrapper .slick-slider .slick-list .slick-track .slick-slide .slider-slide .slider-footer .video-carousel-dots .inner {
  display: flex;
  gap: 16px;
}
.block.imagecards-videocarousel .slider-wrapper .slick-slider .slick-list .slick-track .slick-slide .slider-slide .slider-footer .video-carousel-dots .inner button {
  width: 80px;
  height: 6px;
  border: 0px;
  background-color: #ffffff33;
  cursor: pointer;
  font-size: 0;
}
.block.imagecards-videocarousel .slider-wrapper .slick-slider .slick-list .slick-track .slick-slide .slider-slide .slider-footer .video-carousel-dots .inner button.slick-active {
  background-color: white;
}
.block.imagecards-videocarousel .slider-wrapper .slick-slider .slick-list .slick-track .slick-slide .slider-slide .slider-footer .video-slider-caption h2 {
  width: 75%;
  color: white !important;
  font-size: clamp(1.875rem, 3.75vw, 3.125rem);
  line-height: clamp(2.25rem, 3.75vw, 3.75rem);
  text-transform: uppercase;
}
.block.imagecards-videocarousel .slider-wrapper .slick-slider .slick-list .slick-track .slick-slide .slider-slide .slider-footer .video-slider-caption .slide-button {
  text-align: left;
}
.block.imagecards-videocarousel .slider-wrapper .slick-slider .slick-list .slick-track .slick-slide .slider-slide .slider-footer .video-slider-caption .slide-button .ui.button {
  background-color: unset;
  margin: unset;
  padding: unset;
  color: unset;
  border: unset;
  color: #000000;
  border-radius: unset;
  padding-top: 0.5rem;
  text-transform: uppercase;
  font-size: 1.25rem;
  display: inline-block;
  font-family: 'Precious Sans Two Bold', 'Helvetica Neue', Arial, Helvetica, sans-serif !important;
  font-weight: normal;
  text-decoration: none !important;
  border-bottom: 4px #97cc0a solid;
  padding-left: 0px;
  padding-right: 0px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 4px #28b9f7 solid;
  color: white !important;
  font-size: clamp(0.825rem, 2vw, 1.25rem) !important;
}
.block.imagecards-videocarousel .slider-wrapper .slick-slider .slick-list .slick-track .slick-slide .slider-slide .slider-footer .video-slider-caption .slide-button .ui.button a {
  text-decoration: none !important;
}
.block.imagecards-videocarousel .slider-wrapper .slick-slider .slick-list .slick-track .slick-slide .slider-slide .slider-footer .video-slider-caption .slide-button .ui.button:hover {
  color: unset;
}
.block.imagecards-videocarousel .slider-wrapper .slick-slider .slick-list .slick-track .slick-slide .slider-slide .slider-footer .video-slider-caption .slide-button .ui.button:after {
  display: block;
  border-bottom: solid 4px #FFFFFF;
  margin-bottom: -4px;
  content: '';
  transform: scaleX(0);
  transform-origin: 0% 50%;
  transition: transform 250ms ease-in-out;
}
.block.imagecards-videocarousel .slider-wrapper .slick-slider .slick-list .slick-track .slick-slide .slider-slide .slider-footer .video-slider-caption .slide-button .ui.button:hover:after {
  transform: scaleX(1);
  transform-origin: 0% 50%;
}
.block.imagecards-videocarousel .slider-wrapper .slick-slider .slick-list .slick-track .slick-slide .slider-slide .slider-footer .video-slider-caption .slide-button .ui.button:after {
  display: block;
  border-bottom: solid 4px #FFFFFF;
  margin-bottom: -4px;
  content: '';
  transform: scaleX(0);
  transform-origin: 0% 50%;
  transition: transform 250ms ease-in-out;
}
.block.imagecards-videocarousel .slider-wrapper .slick-slider .slick-list .slick-track .slick-slide .slider-slide .slider-footer .video-slider-caption .slide-button .ui.button:hover:after {
  transform: scaleX(1);
  transform-origin: 0% 50%;
}
.block.imagecards-videocarousel .slider-wrapper .slick-slider .slick-list .slick-track .slick-slide .slider-slide .slider-footer .video-slider-caption .slide-button .ui.button:hover {
  color: white !important;
}
.block.imagecards-videocarousel .slider-wrapper .slick-slider .slick-list .slick-track .slick-slide .slider-slide .slider-action {
  position: absolute;
  top: calc(50% - 64px);
  left: calc(50% - 64px);
  display: flex;
}
.block.imagecards-videocarousel .slider-wrapper .slick-slider .slick-dots {
  bottom: 1em;
  display: flex;
  padding-right: 4em;
  gap: 2em;
  text-align: right;
}
.block.imagecards-videocarousel .slider-wrapper .slick-slider .slick-dots li {
  width: 8%;
  min-width: 20px;
  max-width: 80px;
  height: unset;
  background: #ffffff66;
}
.block.imagecards-videocarousel .slider-wrapper .slick-slider .slick-dots li button {
  height: 6px;
  padding: 0px;
}
.block.imagecards-videocarousel .slider-wrapper .slick-slider .slick-dots li button:before {
  content: '';
}
.block.imagecards-videocarousel .slider-wrapper .slick-slider .slick-dots li.slick-active {
  background: #ffffff;
}
body.view-viewview.contenttype-lrf.has-video-carousel .content-area {
  padding-top: 0em;
  margin-top: 0em !important;
}
body.has-toolbar .slider-caption {
  padding-left: 5em !important;
}
